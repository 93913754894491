import {EActionId, EMapActionId} from 'constant/Log';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect} from 'react';
import {EMarkerType} from 'types/Map';
import useLogger from './useLogger';
import {customEventEmitter, ECustomEvent} from 'utils/customEventEmitter';
import useAppScheme from './useAppScheme';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';

const useCalloutInfoMapManager = () => {
  const dispatch = useAppDispatch();
  const storeState = useAppSelector((state) => state);
  const vsm = useVSMInterfaceConsumer();
  const inApp = useAppScheme();
  const {sendClickLogWithMapView} = useLogger();

  const handleClickPoi = useCallback(
    (feature) => {
      if (feature) {
        const [lon, lat] = feature.geometry.coordinates;
        const {properties} = feature;
        const markerType = properties.type as EMarkerType;

        switch (markerType as EMarkerType) {
          case EMarkerType.SAVE_CLUSTER:
            // 다음 percision index를 찾고, zoom을 정한다. (index+1)
            const percisionList = storeState.remote.saveMapLevelClustering?.cluster || [];
            const currentZoom = Math.floor(vsm.camera?.getZoom() || 0);
            const currentIndex = currentZoom - 1;
            const currentPercision = percisionList[currentIndex];
            const count = properties.count;
            let nextIndex = percisionList.length;
            if (count > 1) {
              for (let i = currentIndex; i <= percisionList.length; i++) {
                if (percisionList[i] !== currentPercision || !percisionList[i]) {
                  nextIndex = i;
                  break;
                }
              }
            }
            vsm.camera?.flyTo({center: [lon, lat], zoom: nextIndex + 1});
            break;
          case EMarkerType.SAVE_POI:
            dispatch(
              actions.userInteraction.setCalloutInfo({
                ...properties,
                markerType: properties.type,
              })
            );
            break;
          case EMarkerType.FAVORITE:
          case EMarkerType.FAVORITE_HOME:
          case EMarkerType.FAVORITE_OFFICE:
          case EMarkerType.FAVORITE_PUBLIC_TRANS:
          case EMarkerType.RECENT_DESTINATION:
            dispatch(
              actions.userInteraction.setCalloutInfo({
                lon: properties.centerLon || properties.wgs84CenterX,
                lat: properties.centerLat || properties.wgs84CenterY,
                centerX: properties.centerX,
                centerY: properties.centerY,
                customName: properties.customName || '',
                navSeq: properties.navSeq,
                navX: properties.navX,
                navY: properties.navY,
                pkey: properties.pkey,
                poiId: properties.poiId,
                stationSktId: properties.stationSktId || properties.stationId,
                rpFlag: properties.rpFlag,
                personalPoiKey: properties.personalPoiKey,
                favId: properties.favId,
                publicTransportType: properties.publicTransportType,
                publicTransportName: properties.publicTransportName,
                markerType,
              })
            );
            break;
          default:
            const publicTransportType = properties?.publicTransportType;
            dispatch(
              actions.userInteraction.setCalloutInfo({
                lon,
                lat,
                poiId: properties.id,
                pkey: properties.pkey,
                stationSktId: properties.stationSktId,
                customName: properties?.customName || '',
                publicTransportType,
                publicTransportName: publicTransportType ? properties.name1 : '',
                markerType: EMarkerType.ACTIVE,
              })
            );
        }
        // click log
        let actionId;
        switch (markerType) {
          case EMarkerType.SAVE_CLUSTER:
            actionId = EMapActionId.TAP_SAVE_CLUSTER_MARKER;
            break;
          case EMarkerType.SAVE_POI:
          case EMarkerType.FAVORITE:
          case EMarkerType.FAVORITE_HOME:
          case EMarkerType.FAVORITE_OFFICE:
          case EMarkerType.FAVORITE_PUBLIC_TRANS:
            actionId = EMapActionId.TAP_FAVORITE_MARKER;
            break;
          case EMarkerType.RECENT_DESTINATION:
            actionId = EMapActionId.TAP_RECENT_DESTINATION_MARKER;
            break;
          default:
            actionId = EMapActionId.TAP_MAP_POI;
            break;
        }
        sendClickLogWithMapView(actionId);
      }
    },
    [
      dispatch,
      sendClickLogWithMapView,
      storeState.remote.saveMapLevelClustering?.cluster,
      vsm.camera,
    ]
  );

  const handleLongPressMap = useCallback(
    (e) => {
      dispatch(
        actions.userInteraction.setCalloutInfo({
          lon: e.point.lon,
          lat: e.point.lat,
          markerType: EMarkerType.ACTIVE,
          poiId: '',
          pkey: '',
          stationSktId: '',
          publicTransportType: undefined,
        })
      );

      sendClickLogWithMapView(EMapActionId.LONG_TAP_MAP);
    },
    [dispatch, sendClickLogWithMapView]
  );

  const handleClickMap = useCallback(
    (e) => {
      if (storeState.userInteraction.calloutInfo) {
        dispatch(actions.userInteraction.clearCalloutInfo());
        sendClickLogWithMapView(EActionId.DIMMED);
        return;
      }

      sendClickLogWithMapView(EMapActionId.TAP_MAP);
    },
    [dispatch, sendClickLogWithMapView, storeState.userInteraction.calloutInfo]
  );

  /**
   * 저장 / 즐겨찾기 update시 콜아웃 마커 업데이트
   */
  useEffect(() => {
    const onChangeFavorite = async (isOn: boolean, poiData: any) => {
      const calloutInfo = storeState.userInteraction.calloutInfo;
      if (!calloutInfo) {
        return;
      }

      const isPublicTransPoi = !!poiData.publicTransportType && !!poiData.stationId;
      if (!isOn) {
        dispatch(
          actions.userInteraction.setCalloutInfo({
            ...calloutInfo,
            ...{
              favId: '',
              personalPoiKey: '',
              markerType: EMarkerType.ACTIVE,
            },
          })
        );
        return;
      }

      // on인경우 + 대중교통
      if (isPublicTransPoi) {
        dispatch(
          actions.userInteraction.setCalloutInfo({
            ...calloutInfo,
            ...{
              markerType: EMarkerType.FAVORITE_PUBLIC_TRANS,
            },
          })
        );
        return;
      }

      // 신규버전 on인경우
      if (isOverNewFavoriteVersion()) {
        const favoriteStateRes = await inApp.getFavoriteState(poiData);
        const resultPoiData = favoriteStateRes.poiDataList[0];
        if (resultPoiData) {
          const color =
            storeState.userInfo.saveGroupList.find((item) => item.groupId === resultPoiData.groupId)
              ?.color || '';
          dispatch(
            actions.userInteraction.setCalloutInfo({
              ...calloutInfo,
              markerType: EMarkerType.SAVE_POI,
              favId: resultPoiData.favId,
              personalPoiKey: `${resultPoiData.favId} + ${resultPoiData.groupId}`,
              // @ts-ignore
              color,
              navY: resultPoiData.navY,
              navX: resultPoiData.navX,
              customName: calloutInfo.customName || resultPoiData.poiName,
            })
          );
        }
      }
    };
    customEventEmitter.on(ECustomEvent.POI_FAVORITE_CHANGED, onChangeFavorite);
    return () => {
      customEventEmitter.off(ECustomEvent.POI_FAVORITE_CHANGED, onChangeFavorite);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inApp, storeState.userInteraction.calloutInfo]);

  return {
    handleClickMap,
    handleClickPoi,
    handleLongPressMap,
  };
};

export default useCalloutInfoMapManager;
